<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column">
        <p v-if="editMode" class="title">Edit E-Newspaper Articles</p>
        <p v-else class="title">Add E-Newspaper Article</p>
      </div>
    </div>
    <hr />
    <form @submit.prevent="submitForm" id="editorForm">
      <div class="columns">
        <div class="column">
          <div class="box">
            <div class="columns">
              <div class="column">
                <b-field label="Kids Content*">
                  <b-switch
                    v-model="formData.kidsContent"
                    type="is-success"
                  ></b-switch>
                </b-field>
                <b-field label="Title*">
                  <b-input v-model="formData.title" required></b-input>
                </b-field>

                <b-field
                  label="Author(s)*"
                  message="Enter multiple values separated by comma (,)"
                >
                  <b-input v-model="formData.authors" required></b-input>
                </b-field>
                <b-field label="Language">
                  <b-input v-model="formData.language"></b-input>
                </b-field>
                <b-field label="Publication Year*">
                  <b-input
                    v-model="formData.publicationYear"
                    required
                  ></b-input>
                </b-field>
                <b-field label="Description*">
                  <b-input
                    v-model="formData.description"
                    required
                    type="textarea"
                  ></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Premium Content*">
                  <b-switch
                    v-model="formData.premiumContent"
                    type="is-success"
                  ></b-switch>
                </b-field>
                <b-field label="Subject*">
                  <b-input v-model="formData.subject" required></b-input>
                </b-field>
                <b-field label="URL Link">
                  <b-input v-model="formData.weblink"></b-input>
                </b-field>
                <b-field
                  label="Keyword(s)*"
                  message="Enter multiple values separated by comma (,)"
                >
                  <b-input v-model="formData.keywords" required></b-input>
                </b-field>
                <b-field label="Publication Date*">
                  <b-datepicker
                    locale="en-GB"
                    v-model="formData.publicationDate"
                    placeholder="Click to select..."
                    icon="calendar-today"
                    trap-focus
                    required
                  >
                  </b-datepicker>
                </b-field>
                <b-field label="Newspaper Source*">
                  <b-input
                    v-model="formData.newspaperSource"
                    required
                  ></b-input>
                </b-field>
              </div>
            </div>
            <hr />
            <div class="has-text-right">
              <b-button @click="cancel" class="button">Cancel</b-button>
              <button
                form="editorForm"
                class="button is-primary ml-4"
                type="submit"
                :disabled="isSubmitting"
              >
                {{ isSubmitting ? "Saving" : "Save" }}
              </button>
            </div>
          </div>
        </div>
        <div class="column  is-4  is-narrow">
          <div v-if="resourceURL" class="box">
            <p class="pb-3 bold has-text-centered">
              <i class="mdi mdi-file-outline pr-1"></i>
              Current Uploads
            </p>
            <hr class="my-1" />
            <div v-if="resourceURL" class="mt-2">
              <div class="bold">
                Attachment
              </div>
              <div class="columns is-vcentered mt-1">
                <div class="column is-narrow pt-1">
                  <a v-if="resourceURL" :href="resourceURL">
                    <b-button type="is-info" icon-left="download">
                      Download
                    </b-button>
                  </a>
                </div>
                <div class="column has-text-right pt-1">
                  <a @click="deleteResource('attachment')">
                    <b-tag type="is-danger is-light">
                      <i class="mdi mdi-close pr-1"></i> Delete
                    </b-tag>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!resourceURL" class="box">
            <b-field label="Attach E-Newspaper Article">
              <section>
                <b-field>
                  <b-upload v-model="attachmentFile" multiple drag-drop>
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="upload" size="is-large"> </b-icon>
                        </p>
                        <p>Drop your file here or click to upload</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>
                <div class="tags">
                  <span
                    v-for="(file, index) in attachmentFile"
                    :key="index"
                    class="tag is-primary"
                  >
                    {{ file.name }}
                    <button
                      class="delete is-small"
                      type="button"
                      @click="deleteattachmentFile(index)"
                    ></button>
                  </span>
                </div>
              </section>
            </b-field>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
export default {
  name: "Editor",
  data() {
    return {
      isSubmitting: false,
      editMode: false,
      formData: {
        title: undefined,
        subject: undefined,
        description: undefined,
        keywords: undefined,
        authors: undefined,
        publicationYear: undefined,
        newspaperSource: undefined,
        publicationDate: undefined,
        language: undefined,
        weblink: undefined,
        premiumContent: false,
        kidsContent: false
      },
      attachmentFile: []
    };
  },
  async created() {
    if (this.$route.params.itemDetail) {
      this.editMode = true;
      const request = {
        resourceId: this.$route.params.itemDetail._id,
        resourceType: "eNewspaper"
      };
      this.$store.dispatch("getResourceUrl", request);
      for (var key in this.$route.params.itemDetail) {
        if (this.$route.params.itemDetail[key]) {
          this.formData[key] = Array.isArray(this.$route.params.itemDetail[key])
            ? this.$route.params.itemDetail[key].join(", ")
            : this.$route.params.itemDetail[key];
        }
      }
      this.formData.publicationDate = new Date(this.formData.publicationDate);
    } else {
      this.$store.dispatch("setResourceUrl", undefined);
    }
  },
  computed: {
    resourceURL() {
      return this.$store.state.resourceUrl;
    }
  },
  methods: {
    deleteattachmentFile(index) {
      this.attachmentFile.splice(index, 1);
    },
    cancel() {
      this.$router.push({ name: "ENewspaper" });
    },
    async deleteResource(type) {
      const deleteResourceRequest = {
        resourceId: this.$route.params.itemDetail._id,
        resourceType: "eNewspaper",
        type: type
      };
      await this.$store
        .dispatch("resourceDelete", deleteResourceRequest)
        .then(() => {
          if (type === "attachment") {
            this.formData.eResearchReportAttachment = undefined;
            this.$store.dispatch("setResourceUrl", undefined);
          }
        });
    },
    async submitForm() {
      this.isSubmitting = true;
      const request = cloneDeep(this.formData);
      request["keywords"] = request["keywords"].split(",").map(function(item) {
        return item.trim();
      });
      request["authors"] = request["authors"].split(",").map(function(item) {
        return item.trim();
      });
      if (this.editMode) {
        const uploadRequest = new FormData();
        uploadRequest.append("attachment", this.attachmentFile[0]);
        uploadRequest.append("attachmentTitle", this.formData.title);
        uploadRequest.append("resourceId", this.$route.params.itemDetail._id);
        uploadRequest.append("resourceType", "eNewspaper");
        if (this.attachmentFile[0]) {
          await this.$store.dispatch("attachmentUpload", uploadRequest);
        }

        if (
          await this.$store.dispatch("editENewspaper", {
            id: this.$route.params.itemDetail._id,
            request: request
          })
        ) {
          this.$router.push({ name: "ENewspaper" });
        }
      } else {
        const eNewspaperObj = await this.$store.dispatch(
          "addENewspaper",
          request
        );
        if (eNewspaperObj) {
          const uploadRequest = new FormData();
          uploadRequest.append("attachment", this.attachmentFile[0]);
          uploadRequest.append("attachmentTitle", eNewspaperObj.title);
          uploadRequest.append("resourceId", eNewspaperObj._id);
          uploadRequest.append("resourceType", "eNewspaper");
          if (this.attachmentFile[0]) {
            await this.$store.dispatch("attachmentUpload", uploadRequest);
          }
          this.$router.push({ name: "ENewspaper" });
        }
      }
      this.isSubmitting = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
